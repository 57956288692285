import React from 'react'
import { Card, Text, IconButton } from 'theme-ui'
import { FaPhone, FaEnvelope, FaLocationArrow } from 'react-icons/fa'
import Section from '@components/Section'
import Navigation from '@components/Navigation'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'
import attachSocialIcons from '@helpers/attachSocialIcons'

const ContactInfo = () => {
  const { phone, address, email } = useSiteMetadata()
  const { social } = useSiteMetadata()

  return (
    <Section aside title='Contacting Me'>
      <Card variant='paper'>
        <Text variant='p'>Hoping to get in touch?</Text>
        <Text variant='p'>
         Please send me a text. I'll try to get back in touch ASAP.
        </Text>
        {phone && (
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaPhone />
            </IconButton>
            {phone}
          </Text>
        )}
        {email && (
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaEnvelope />
            </IconButton>
            {email}
          </Text>
        )}
        {address && (
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaLocationArrow />
            </IconButton>
            {address}
          </Text>
        )}
        <Navigation items={attachSocialIcons(social)} iconOnly />
      </Card>
    </Section>
  )
}

export default ContactInfo
